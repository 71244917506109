export const providerData = [
  {
    name: 'Dr. Elena Cherepanov',
    bio: 'Dr. Elena Cherepanov is a clinical psychologist by training and is licensed in MA as a mental health counselor. She works as behavioral health lead for the integrated refugee services at Lynn Community Health Center. These services include counseling, prevention, education and addressing cultural and social determinants of health and wellness for refugees and immigrants resettled in Greater Lynn area. Elena Cherepanov, PhD is a professor at the Graduate school of psychology and counseling at Cambridge college (Boston, MA). Her expertise is in global mental health, multicultural integrated care, and systems of trauma which include cultural trauma and the response to humanitarian emergencies.She has over 20 years of humanitarian work experience and is the author of over 60 papers and books.',
  },
  {
    name: 'Alissa Fodor, APRN, PMHNP',
    bio: "Alissa Fodor, APRN, PMHNP, is a Board Certified Psychiatric Nurse Practitioner licensed in Massachusetts. Much of her past professional experience and current practice has been devoted to performing evaluations and consultations for patients and their loved ones who might be frightened or confused about mental problems and are looking for help and guidance. 'I believe that medical and holistic approach to your mental health is the ultimate solution to take charge of your life and look into the future without any regret and fear. Take a step back and think about yourself. What changes do you want to make for the future? Today is the first day of the rest of your life; make it count!'",
  },
  {
    name: 'Marina Livshits, Psy.D.',
    bio: 'Marina Livshits is a clinical psychologist with over 25 years of experience with individuals affected by displacement, separation and interpersonal trauma. Utilizing her specialized training, academic and clinical experience she served many immigrant and refugee communities affected by armed conflict, gang violence, racism, and family disruptions due to domestic violence. She provided international trauma-informed agency consultations and taught on subjects of trauma-informed treatments at Boston University School of Medicine, Northeastern University, Simmons College, Salem State University, and Cambridge College. Marina was recognized for her work with trafficked women by Massachusetts Office of Victim Assistance (MOVA) with an Innovation Award. Her clinical work with trauma affected individuals is focused on coping, self-compassion and upholding the strength of human spirit.',
  },
  {
    name: 'Dr. Irene Piryatinsky',
    bio: 'Dr. Irene Piryatinsky is a board-certified and licensed clinical neuropsychologist with years of clinical experience. As the war in Ukraine erupted, children, young people, and adults are often deeply vulnerable.  As one becomes displaced and finds themselves in a state of chaos and horror, I. am hoping to provide assistance in screening and evaluation for those who may be suffering from PTSD, anxiety, depression, burnout, and fatigue.',
  },
];

export const crisisCenterDataRussia = [
  {
    name: 'Кризисная линия доверия (круглосуточно)',
    number: '8 (800) 100-49-94',
  },
  {
    name: 'Горячая линия Центра экстренной психологической помощи МЧС России',
    number: '8 (495) 989-50-50;',
    website: 'http://psi.mchs.gov.ru',
  },
  {
    name: 'Горячая линия психологической поддержки Благотворительного фонда «Просто люди»',
    number: '8 (495) 025 15 35',
  },
  {
    name: 'Бесплатная кризисная линия доверия  – круглосуточные экстренные консультации психолога в области жизненных проблем',
    number: '8 (495) 988-44-34 (бесплатно по Москве)',
    number2: '8 (800) 333-44-34 (бесплатно по России)',
  },
  {
    name: 'Московская служба психологической помощи населению',
    number: '051',
  },
  {
    name: 'Экстренная медико-психологическая помощь в кризисных ситуациях Департамента здравоохранения города Москвы',
    number: '8 (499) 791-20-50',
  },
  {
    name: 'Телефон доверия экстренной психологической помощи',
    number: '8 (495) 575-87-70',
  },
  {
    name: 'Независимая психиатрическая ассоциация',
    number: '8 (495) 625-06-20',
  },
  {
    name: 'Государственная скорая психиатрическая помощь (Москва)',
    number: '8 (495) 625-31-01',
  },
  {
    name: 'Психиатрическая клиническая больница № 1 им. Н.А. Алексеева (Москва), приемное отделение',
    number: '8 (495) 952-91-61',
  },
  {
    name: 'Психиатрическая клиническая больница № 4 имени П.Б. Ганнушкина (Москва), справочная',
    number: '8 (495) 963-11-02',
  },
  {
    name: 'Центральная клиническая психиатрическая больница (Москва), приемный покой',
    number: '8 (495) 614-19-11',
  },
  {
    name: 'Психологическая помощь детям и подросткам онлайн',
    website: 'https://pomoschryadom.ru/',
  },
  {
    name: 'Телефон доверия для детей и подростков',
    number: '8 (495) 160-03-63',
  },
  {
    name: 'Телефон доверия для помощи детям, подросткам и родителям',
    number: '8 (800) 200-01-2',
  },
  {
    name: 'Горячая линия для людей с алкогольной и наркотической зависимостью',
    number: '8 (495) 126-04-51',
  },
  {
    name: 'Горячая линия социально-психологической помощи детям и семьям мигрантов, находящимся в трудной жизненной ситуации',
    number: '8 (499) 201-59-47',
  },
];

export const crisisCenterDataUkraine = [
  {
    name: 'Телефон горячей линии психологической помощи',
    number: '0800211444',
  },
  {
    name: 'Ассоциация профессионального развития психологов и психотерапевтов в Украине с 10:00 до 21:00',
    number: '073 420 0330',
  },
  {
    name: '«Телефон доверия» по номерам',
    number: '(044) 456 17 02 та (044) 456 17 25',
  },
  {
    name: 'Горячая линия «Давай поговорим» бесплатно оказывает психологическую помощь по номеру',
    number: '0 800 331 800',
  },
  {
    name: 'Медико-психологическая служба доверия (круглосуточно)',
    number: '044) 456-17-25',
  },
  {
    name: 'Лінія запобігання самогубствам дзвони зараз',
    number: '7333',
  },
];

export const crisisCenterDataBelarus = [
  {
    name: 'Служба экстренной психологической помощи «Телефон доверия»',
    number:
      '8 029 899 04 01; Телеграм(Telegram), Вайбер (Viber), Ватсап (WhatsApp): 8 029 899 04 01',
  },
  {
    name: 'Республиканский центр психологической помощи',
    number: '8 017 300 10 06',
  },
  {
    name: '«Минский областной клинический центр «Психиатрия — наркология», круглосуточная служба экстренной психологической помощи «Телефон доверия»',
    number: '8 017 202 04 01',
  },
  {
    name: 'Республиканская телефонная "горячая линия"по оказанию психологической помощи несовершеннолетним, попавшим в кризисную ситуацию',
    number: '8 801 100 16 11',
  },
  {
    name: 'Интернет-служба экстренной психологической помощи',
    website: 'https://psi.mchs.gov.ru/',
  },
];
